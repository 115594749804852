import { GameHelper } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import '../utils/cardSettings';
import { selectStateError } from 'app/selector';
import ReplayBoard from 'games/ReplayBoard';
import ReplayPanel from 'games/ReplayPanel';
import { NotFound } from './NotFound';
import { useState } from 'react';
import { isIOS } from 'react-device-detect';

export const MatchReplay: React.FC = () => {
    const [searchParams] = useSearchParams();
    const error = useSelector(selectStateError);

    const [description, setDescription] = useState<any>(null);
    const game = searchParams.get('game');
    const tourId = searchParams.get("tournament");
    const session = searchParams.get("session");
    const id = searchParams.get('id');
    const tableId = searchParams.get('table');
    return (
        <div className={`relative main-page-history ${GameHelper.isMobile() ? 'mobile' : 'desktop'} ${isIOS && GameHelper.isMobile() ? "ios-mobile" : ""}`} >
            {game && !error ? (
                <>
                    <ReplayBoard game={game} onUpdate={e => setDescription(e)} />
                    {(tourId || session || id || tableId) && (<ReplayPanel description={description} />)}
                </>
            ) : (<NotFound />)}
        </div>
    )
}
