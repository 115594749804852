export const { ScopaReplay } =
    process.env.REACT_APP_NAMESPACE_SCOPA ? require(process.env.REACT_APP_NAMESPACE_SCOPA)
        : require('@quanticogames/gameclient-scopa');

export const { ItPokerReplay, ItPokerCashReplay } =
    process.env.REACT_APP_NAMESPACE_ITALIANPOKER ? require(process.env.REACT_APP_NAMESPACE_ITALIANPOKER)
        : require('@quanticogames/gameclient-italianpoker');

export const { Scala40Replay } =
    process.env.REACT_APP_NAMESPACE_SCALA40 ? require(process.env.REACT_APP_NAMESPACE_SCALA40)
        : require('@quanticogames/gameclient-scala40');

export const { BurracoReplay } =
    process.env.REACT_APP_NAMESPACE_BURRACO ? require(process.env.REACT_APP_NAMESPACE_BURRACO)
        : require('@quanticogames/gameclient-burraco');

export const { BriscolaReplay } =
    process.env.REACT_APP_NAMESPACE_BRISCOLA ? require(process.env.REACT_APP_NAMESPACE_BRISCOLA)
        : require('@quanticogames/gameclient-briscola');

export const { AssoPigliaTuttoReplay } =
    process.env.REACT_APP_NAMESPACE_ASSOPIGLIATUTTO ? require(process.env.REACT_APP_NAMESPACE_ASSOPIGLIATUTTO)
        : require('@quanticogames/gameclient-asso-pigliatutto');

export const { BestiaReplay } =
    process.env.REACT_APP_NAMESPACE_BESTIA ? require(process.env.REACT_APP_NAMESPACE_BESTIA)
        : require('@quanticogames/gameclient-bestia');

export const { TresetteReplay } =
    process.env.REACT_APP_NAMESPACE_TRESETTE ? require(process.env.REACT_APP_NAMESPACE_TRESETTE)
        : require('@quanticogames/gameclient-tresette');

export const { CirullaReplay } =
    process.env.REACT_APP_NAMESPACE_CIRULLA ? require(process.env.REACT_APP_NAMESPACE_CIRULLA)
        : require('@quanticogames/gameclient-cirulla');

export const { ScoponeReplay } =
    process.env.REACT_APP_NAMESPACE_SCOPONE ? require(process.env.REACT_APP_NAMESPACE_SCOPONE)
        : require('@quanticogames/gameclient-scopone');

export const { SetteEMezzoReplay } =
    process.env.REACT_APP_NAMESPACE_SETTE_E_MEZZO ? require(process.env.REACT_APP_NAMESPACE_SETTE_E_MEZZO)
        : require('@quanticogames/gameclient-setteemezzo');

export const { TelesinaCashReplay, TelesinaReplay } =
    process.env.REACT_APP_NAMESPACE_TELESINACASH ? require(process.env.REACT_APP_NAMESPACE_TELESINACASH)
        : require('@quanticogames/gameclient-telesina');

        
export const { PeppaReplay } =
    process.env.REACT_APP_NAMESPACE_PEPPA ? require(process.env.REACT_APP_NAMESPACE_PEPPA)
        : require('@quanticogames/gameclient-peppa');

export const { SolitarioReplay } =
    process.env.REACT_APP_NAMESPACE_SOLITARIO ? require(process.env.REACT_APP_NAMESPACE_SOLITARIO)
        : require('@quanticogames/gameclient-solitario');

export const { TrentunoReplay } =
    process.env.REACT_APP_NAMESPACE_TRENTUNO ? require(process.env.REACT_APP_NAMESPACE_TRENTUNO)
        : require('@quanticogames/gameclient-trentuno');

export const { GinRummyReplay } =
    process.env.REACT_APP_NAMESPACE_GIN_RUMMY ? require(process.env.REACT_APP_NAMESPACE_GIN_RUMMY)
        : require('@quanticogames/gameclient-gin-rummy');