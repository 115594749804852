import { BrowserRouter } from 'react-router-dom';

import './App.css';
import { Router } from './routes';
import { useEffect, useState } from 'react';
import i18n from 'i18n';
import { GameHelper } from '@quanticogames/gameclient-core';
import { isMobileApp } from 'utils/mobile';
import eventEmitter from 'services/eventEmitter';
import UnexpectedPage from 'unexpected-page/UnexpectedPage';

function App() {
  const [isShowSystemMaintenance, setIsShowSystemMaintenance] = useState<boolean>(false);

  useEffect(() => {
    eventEmitter.on("system-maintenance", () => {
      setIsShowSystemMaintenance(true);
    });

    const handleLanguageChange = () => {
      const newLang = localStorage.getItem("i18nextLng");
      i18n.changeLanguage(newLang);
    };
    window.addEventListener('storage', handleLanguageChange);
    return () => {
      window.removeEventListener('storage', handleLanguageChange);
    };
  }, []);
  const updateOrientation = () => {
    const isLandscape = GameHelper.isLandscape() && GameHelper.isMobile();
    if (isMobileApp() && window['ReactNativeWebView']) {
      window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'orientation', payload: isLandscape ? 'landscape' : 'portrait' }));
    }
  }

  useEffect(() => {
    updateOrientation()
    window.addEventListener(
      'resize',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'resize',
        updateOrientation
      )
    }
  }, [])

  if(isShowSystemMaintenance) {
    return (
      <UnexpectedPage />
    )
  }
  useEffect(() => {
    if (!window.location.pathname.startsWith('/gamehistory/')) {
      window.location.replace('/gamehistory/');
    }
  }, [window.location.pathname]);

  return (
    <BrowserRouter basename='/gamehistory'>
      <Router />
    </BrowserRouter>
  );
}

export default App;
