import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './lang/en';
import it from './lang/it';

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'it')
}

i18n.use(LanguageDetector).use(initReactI18next).init({
    detection: {
        lookupQuerystring: 'lang',
        htmlTag: document.documentElement,
        order: ['querystring', 'navigator', 'localStorage'],
    },
    interpolation: {
        escapeValue: false,
    },
    ns: ['common', 'briscola', 'burraco', 'itPoker', 'scala40', 'scopa', 'bestia', 'cirulla', 'tresette', 'setteEMezzo', 'peppa', 'solitario', 'trentuno', 'telesina'],
    defaultNS: 'common',
    resources: {
        en,
        it,
    }
});

i18n.changeLanguage('it')

export default i18n;
