import { CardTypeEnum, DeckType, cardTypeManager } from '@quanticogames/gameclient-core';
import { italianToFrench } from 'utils/italianToFrench';

// the path of decks can be changed
cardTypeManager.path = process.env.REACT_APP_ASSET_URL ? '../gamehistory/images/cards' : '../images/cards';

cardTypeManager.set(DeckType.French, [
    { name: 'French', type: CardTypeEnum.Poker, code: 'poker', ext: 'png' },
    { name: 'Lombarde', type: CardTypeEnum.Lombarde, code: 'lombarde', ext: 'webp' },
]);

cardTypeManager.set(DeckType.Italian, [
    { name: 'Napoletane', type: CardTypeEnum.Napoletane, code: 'napoletane', ext: 'jpg' },
    { name: 'Piacentine', type: CardTypeEnum.Piacentine, code: 'piacentine', ext: 'webp' },
    { name: 'Lombarde', type: CardTypeEnum.Lombarde, code: 'lombarde', ext: 'webp', mapping: italianToFrench },
    { name: 'Siciliane', type: CardTypeEnum.Siciliane, code: 'siciliane', ext: 'webp' },
    { name: 'Romagnole', type: CardTypeEnum.Romagnole, code: 'romagnole', ext: 'webp' },
    { name: 'Sarde', type: CardTypeEnum.Sarde, code: 'sarde', ext: 'webp' },
    { name: 'Toscane', type: CardTypeEnum.Toscane, code: 'toscane', ext: 'webp' },
    { name: 'Triestine', type: CardTypeEnum.Triestine, code: 'triestine', ext: 'webp' }
]);