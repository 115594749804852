import {
  BriscolaReplay,
  ItPokerReplay,
  Scala40Replay,
  BurracoReplay,
  ScopaReplay,
  AssoPigliaTuttoReplay,
  BestiaReplay,
  TresetteReplay,
  CirullaReplay,
  ScoponeReplay,
  ItPokerCashReplay,
  TelesinaCashReplay,
  TelesinaReplay,
  SetteEMezzoReplay,
  PeppaReplay,
  SolitarioReplay,
  TrentunoReplay,
  GinRummyReplay
} from "./require";

export interface ComponentTypeMap {
  [key: string]: React.ComponentType<any>;
}

// Define game component types and their corresponding React components
export const componentTypes: ComponentTypeMap = {
  Scopa: ScopaReplay,
  ItalianPoker: ItPokerReplay,
  Burraco: BurracoReplay,
  Scala40: Scala40Replay,
  Briscola: BriscolaReplay,
  AssoPigliaTutto: AssoPigliaTuttoReplay,
  Bestia: BestiaReplay,
  Tresette: TresetteReplay,
  Cirulla: CirullaReplay,
  Scopone: ScoponeReplay,
  ItalianPokerCash: ItPokerCashReplay,
  TelesinaCash: TelesinaCashReplay,
  Telesina: TelesinaReplay,
  SetteEMezzo: SetteEMezzoReplay,
  SetteEMezzoCash: SetteEMezzoReplay,
  Peppa: PeppaReplay,
  Solitario: SolitarioReplay,
  Trentuno: TrentunoReplay,
  GinRummy: GinRummyReplay,
};
