import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ArrowBackIos, ArrowForwardIos, Close, FirstPage, KeyboardArrowUp, LastPage } from "@mui/icons-material";
import { Box, MenuItem, Select } from "@mui/material";
import moment from 'moment-timezone';
import { fetchData, updateParams } from "app/actions";
import { selectStateParams, selectStateTotal } from "app/selector";
import { fetchRounds } from "services/history.service";

interface ReplayPanelProps {
  description: any;
}

const gameCodes = ['SetteEMezzo', 'ItalianPoker'];

const ReplayPanel: React.FC<ReplayPanelProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const total = useSelector(selectStateTotal);
  const params = useSelector(selectStateParams);
  const [searchParams] = useSearchParams();
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState<number | string>('');
  const [selectedInteraction, setSelectedInteraction] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [isClose, setIsClose] = useState<boolean>(false);

  const gameCode = searchParams.get("game");
  const tableId = searchParams.get("table");
  const tourId = searchParams.get("tournament");
  const isBO = searchParams.get("bo") === "true";
  const session = searchParams.get("session");
  const playerId = searchParams.get("player");
  const id = searchParams.get("id");
  const isCashTable = !!(session || tableId);

  const currentTimezone = useMemo(() => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || '';
  }, []);
  const timeZone = moment.utc(props.description?.time).tz(currentTimezone);

  const moveDescription = useMemo(() => {
    if (!props.description?.message) return "";
    if (gameCodes.includes(gameCode)) return props.description?.message;
    return `${props.description?.player} ${props.description?.message}`;
  }, [gameCode, props?.description]);


  const updateInteraction = (value: number) => {
    const newInteraction = params.interactionId + value;
    if (newInteraction < 1 || newInteraction > total) return;
    handleChangeInteraction(newInteraction);
  };

  const handleChangeInteraction = (value: number) => {
    setSelectedInteraction(value);
    dispatch(updateParams({ interactionId: value }));
  };

  const handleChangeRound = (value: number) => {
    setSelectedRound(value);
    setSelectedInteraction(1);
    dispatch(updateParams({
      matchId: rounds[value].matchId,
      roundId: rounds[value].roundId,
      interactionId: 1,
    }));
  };

  const getRounds = async (search: any) => {
    let res: any[] = await fetchRounds(search, isBO);
    if (id) res = res.filter(item => item.matchId === Number(id));
    const newRounds = (res || []).reduce(
      (rs: any[], match) => {
        let r = [];
        if (match?.isChipGame) {
          r = r.concat((match?.roundIds || []).map(
            id => ({
              matchId: match?.matchId,
              roundId: id,
            })
          ));
        } else r.push({
          matchId: match?.matchId,
          roundId: 1,
        });
        return rs.concat(r);
      },
      []
    ).map((item, id) => ({ ...item, id }));
    setRounds(newRounds);
    if (newRounds.length) {
      setSelectedRound(newRounds[0].id);
      dispatch(updateParams({
        gameCode,
        matchId: newRounds[0].matchId,
        roundId: newRounds[0].roundId,
        interactionId: 1,
      }));
    };
  };

  const generateRoundLabel = (round: any) => {
    return `${t('Match')} ${round.matchId} - ${t('Round')} ${round.roundId}`;
  };

  useEffect(() => {
    const search = {
      ...(tourId ? { tournamentId: tourId } : {}),
      ...(session ? { sessionId: session } : {}),
      ...(id || tableId ? { matchId: id || tableId } : {}),
      ...(playerId ? { playerId } : {}),
      isCashTable,
      gameCode,
    };
    if (Object.keys(search).length) getRounds(search);
  }, [tourId, session, id, tableId, playerId, gameCode]);

  useEffect(() => {
    if (params.playerId !== currentPlayer) {
      setCurrentPlayer(params.playerId);
      setSelectedInteraction(1);
    }

    if (Object.values(params).every(value => value !== null)) {
      if (gameCode === 'Solitario' && params.playerId === 0) {
        return
      }
      dispatch(fetchData({ ...params, isCashTable, isBO }));
    };
  }, [params]);

  return (
    <>
      <div className="fixed bottom-[10px] right-[15px] z-10 scoring-history">
        <Box className="flex items-end flex-wrap flex-col scoring-history-container">
          {isClose ? (
            <></>
          ) : (
            <div
              className="table-history-close bg-[#f00] cursor-pointer hover:bg-[#9a0101] text-[#fff] rounded-[50%] w-[35px] h-[35px] flex items-center justify-center absolute top-[-20px] right-[-10px]"
              onClick={() => setIsClose(true)}
            >
              <Close sx={{ width: 26, height: 26 }} />
            </div>
          )}
          {!isClose && (
            <div className="table-history-info bg-[#092020] rounded-[8px] p-[10px] text-[#fff] w-full">
              <div
                className="table-line table-line-action pb-[7px] !pl-0"
                style={{ borderBottom: '1px solid rgba(249, 247, 247, .28)' }}
              >
                <Select
                  value={selectedRound}
                  variant="outlined"
                  className="h-[38px] text-center rounded-[8px] bg-[#fff]"
                  onChange={(e: any) => handleChangeRound(e.target.value)}
                  sx={{ padding: 0, overflow: 'hidden' }}
                >
                  {rounds.map((round) => (
                    <MenuItem key={round.id} value={round.id}>
                      {generateRoundLabel(round)}
                    </MenuItem>
                  ))}
                </Select>
                <div className="mt-2">
                  {t("Interaction")} {params.interactionId} / {props.description?.time ? timeZone.format("DD/MM/YYYY HH:mm:ss") : ""}
                </div>
              </div>
              {moveDescription?.length > 0 ? (
                <div className="py-[15px] text-[16px]">{moveDescription}</div>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="flex gap-[10px] w-full">
            <div className="flex gap-[10px] flex-1">
              <button
                disabled={selectedInteraction === 1}
                onClick={() => handleChangeInteraction(1)}
                className="w-[38px] h-[38px] lg:w-[45px] lg:h-[45px] flex items-center justify-center lg:min-w-[45px] min-w-[40px] rounded-[50%] bg-[#092020] text-[#fff]"
              >
                <FirstPage sx={{ width: 26, height: 26 }} />
              </button>
              <button
                disabled={selectedInteraction === 1}
                onClick={() => updateInteraction(-1)}
                className="w-[38px] h-[38px] lg:w-[45px] lg:h-[45px] flex items-center justify-center lg:min-w-[45px] min-w-[40px] rounded-[50%] bg-[#092020] text-[#fff]"
              >
                <ArrowBackIos sx={{ width: 18, height: 18 }} />
              </button>
              <Select<number>
                value={total > 0 ? selectedInteraction : ''}
                variant="outlined"
                className="bg-[#092020] lg:h-[45px] h-[38px] text-center rounded-[8px] table-history-input flex-1"
                onChange={(e: any) => handleChangeInteraction(e.target.value)}
                sx={{
                  padding: 0,
                  overflow: 'hidden',
                  color: '#fff',
                  '.MuiSvgIcon-root ': {
                    fill: "#fff !important",
                  },
                }}
              >
                {Array.from(Array(total)).map((_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                ))}
              </Select>
              <button
                disabled={selectedInteraction === total}
                onClick={() => updateInteraction(1)}
                className="w-[38px] h-[38px] lg:w-[45px] lg:h-[45px] flex items-center justify-center lg:min-w-[45px] min-w-[38px] rounded-[50%] bg-[#092020] text-[#fff]"
              >
                <ArrowForwardIos sx={{ width: 18, height: 18 }} />
              </button>
              <button
                disabled={selectedInteraction === total}
                onClick={() => handleChangeInteraction(total)}
                className="w-[38px] h-[38px] lg:w-[45px] lg:h-[45px] flex items-center justify-center lg:min-w-[45px] min-w-[38px] rounded-[50%] bg-[#092020] text-[#fff]"
              >
                <LastPage sx={{ width: 26, height: 26 }} />
              </button>
            </div>
            <div
              className="table-history-open bg-[#092020] cursor-pointer md:hover:bg-[#004b4b] text-[#fff] rounded-[50%] w-[38px] h-[38px] flex items-center justify-center"
              onClick={() => setIsClose(!isClose)}
            >
              <KeyboardArrowUp sx={{ width: 26, height: 26 }} />
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default ReplayPanel;
