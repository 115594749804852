export const Loading = () => {
    const prefix = (process.env.REACT_APP_ASSET_URL ?? '');
    return (
        <div className="loader-container fixed top-0 left-0 w-[100vw] h-[100vh] bg-[#123335] z-[9999]">
            <img
                src={`${prefix}/images/common/loading_circle.svg`}
                className="loader absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[50px]"
            />
        </div>
    );
}